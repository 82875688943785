/**
 *  `govuk-visually-hidden`
 */
%invisible {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);

  border: 0;

  white-space: nowrap;
}

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  border: none;

  font-size: 16px;
  line-height: 24px;

  background-color: white;

  width: 100%;
  height: 100%;

  font-family: 'Roboto', sans-serif;
}

h1, p {
  margin: 0;
  padding: 0;
  border: none;

  text-align: center;
}

h1 {
  font-size: 3rem;
  line-height: 4.5rem;

  display: block;

  width: 280px;
  height: 636px;

  margin: 0 auto;

  background: transparent url('../png/modernpoacher.png') no-repeat top center;
  background-size: contain;

  span {
    @extend %invisible;
  }
}

header {
  font-size: 1rem;
  line-height: 1.375rem;

  position: fixed;
  right: 0;
  top: 0;

  margin: 1.25rem;

  color: #4b5d5d;

  a {
    display: block;

    outline-offset: .25rem;
  }

  svg {
    display: block;

    width: 84px;
    height: 30px;

    path {
      fill: #151013; // #24292e;
    }
  }
}

footer {
  font-size: 1rem;
  line-height: 1.375rem;

  position: fixed;
  left: 0;
  bottom: 0;

  margin: 1.25rem;

  color: #4b5d5d;
}

section {
  height: 100%;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;

  display: block;

  text-align: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #ccffff;

  padding: 4em 0;
  letter-spacing: 0.1em;

  span {
    display: block;

    a {
      color: #262f2f;

      outline-offset: .25rem;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 736px) {
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media screen and (orientation: portrait) and (max-width: 414px) {
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media screen and (orientation: landscape) {
  html,
  body,
  section {
    min-width: 568px;
    min-height: 320px;
  }
}

@media screen and (orientation: portrait) {
  html,
  body,
  section {
    min-height: 568px;
    min-width: 320px;
  }
}

@media screen and (max-height: 824px) {
  section p {
    opacity: 0.85;
  }
}
